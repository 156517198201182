

import { Checkbox, ICheckboxProps, IRenderFunction } from '@fluentui/react';
import { FC, useCallback } from 'react';
import { IMessageItem } from '../../model/model';
import ProfilePhoto from '../chatArchiving/ProfilePhoto';

interface IMultiCheckFilterProps {
    items: IMessageItem[];
    selectedItems: string[];
    showProfilePhoto?: boolean;
    onCheckChanged: (item: string, checked: boolean) => void;
}
// Extended the ButtonHTMLAttributes type to include the custom attribute
interface CustomButtonHTMLAttributes<T> extends React.ButtonHTMLAttributes<T> {
    'data-message-item'?: string;
    'data-show-profile-photo'?: boolean;
}

// Extended the ICheckboxProps interface to include the custom inputProps
interface CustomCheckboxProps extends ICheckboxProps {
    inputProps?: CustomButtonHTMLAttributes<HTMLElement | HTMLButtonElement>;
}

const MultiCheckFilter: FC<IMultiCheckFilterProps> = (props) => {

    const onCheckChange = useCallback((item: string) => (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        props.onCheckChanged(item, checked as boolean);
    }, [props]);

    const onRenderLabel: IRenderFunction<CustomCheckboxProps> = useCallback(
        (props, defaultRender) => (
            <div style={{ display: 'flex', flexDirection: 'row', columnGap: '10px', marginLeft: '5px' }}>
                <ProfilePhoto item={JSON.parse(props?.inputProps?.['data-message-item'] as string) as IMessageItem}
                    showProfilePhoto={props?.inputProps?.['data-show-profile-photo'] as boolean} />
            </div>
        ), []);

    const RenderCheckbox = useCallback((item: IMessageItem, selectedItems: string[], onCheckChange: (item: string) => (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => void): JSX.Element => {
        const inputProps: CustomButtonHTMLAttributes<HTMLElement | HTMLButtonElement> = {
            'data-message-item': JSON.stringify(item),
            'data-show-profile-photo': props.showProfilePhoto
        };

        return <Checkbox onRenderLabel={onRenderLabel} inputProps={inputProps} label={item.fromUser as string} checked={selectedItems.includes(item.fromUser as string)}
            onChange={onCheckChange(item.fromUser as string)} />;
    }, [onRenderLabel, props.showProfilePhoto]);

    return <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        {props.items?.map((item, index) => (
            <div key={item.fromUserId} style={{ marginBottom: '10px' }}>
                {RenderCheckbox(item, props.selectedItems, onCheckChange)}
            </div>
        ))}
    </div>;
};

export default MultiCheckFilter;