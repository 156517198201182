import { FC, useCallback, useState } from 'react';
import { DefaultButton, IconButton, IPanelProps, IRenderFunction, Panel, PanelType, PrimaryButton } from '@fluentui/react';
import { t } from "i18next";
import { ICommonProps } from '../../ICommonProps';
import { classNames } from '../../../styles/MergeStyleSets';
import { MessageCaseView } from './MessageCaseView';
import { ArchiveChatsOnExistingDocumentsRequestParameter, EnumSearchCriteria, ICaseDocumentRequest, IDocumentResponse, IMessageItem, ITeamRequest } from '../../../model/model';
import { mapMessageItemToMessageResponse, MapTeamsContextToITeamRequest } from '../../../helpers/Mapper';
import { useTeams } from '../../../context/TeamsContext';
import { useMessages } from '../../../context/MessagesContext';
import { useBoolean } from '@fluentui/react-hooks';

interface IChatArchivePanelProps extends ICommonProps, IPanelProps {
    isOpen: boolean;
    selectedMessages?: IMessageItem[];
    includeAttachments: boolean;
    onSaveComplete?(response: IDocumentResponse): void;
    onSaveError?(error: Error): void;
}

const ChatArchivePanel: FC<IChatArchivePanelProps> = (props) => {
    const { teamsState } = useTeams();
    const { messageState } = useMessages();
    const [isSavingInProgress, { setTrue: savingInProgress, setFalse: savingFinished }] = useBoolean(false);
    const [isDocumentSearch, setIsDocumentSearch] = useState<boolean>(false);
    const [existingCaseDocumentProps, setExistingCaseDocumentProps] = useState<ICaseDocumentRequest>();

    const closePanel = useCallback(() => {
        props.onDismiss && props.onDismiss();
    }, [props]);

    const onContinueClick = useCallback(() => {
        closePanel();
    }, [closePanel]);

    const onSaveClick = useCallback(async () => {
        savingInProgress();
        const token = await teamsState.getAccessToken();
        const request: ArchiveChatsOnExistingDocumentsRequestParameter = {
            AccessToken: token,
            TeamRequest: MapTeamsContextToITeamRequest(teamsState.userContext) as ITeamRequest,
            DocumentNumber: existingCaseDocumentProps?.documentNumber as string,
            MessageResponse: mapMessageItemToMessageResponse(messageState, props.selectedMessages),
            IncludeAttachments: props.includeAttachments
        };
        const response = await props.dataProvider?.P360.archiveChatMessagesOnExistingDocument(request);
        if (response?.data) {
            props.onSaveComplete && props.onSaveComplete(response.data);
        }
        savingFinished();
        closePanel();
    }, [closePanel, existingCaseDocumentProps?.documentNumber, messageState, props, savingFinished, savingInProgress, teamsState]);

    const onClick = useCallback(() => {
        isDocumentSearch ? onSaveClick() : onContinueClick();
    }, [isDocumentSearch, onContinueClick, onSaveClick]);

    const onRenderNavigationContent: IRenderFunction<IPanelProps> = useCallback(
        (props, defaultRender) => (
            <div className={classNames.messagePanelHeaderContainer}>
                <div className={classNames.messagePanelHeader}>
                    <div className={classNames.messagePanelHeaderIcon}>
                        <img src='https://static2.sharepointonline.com/files/fabric/assets/item-types/16/folder.svg' width={50} height={50} alt={t('Folder')} />
                    </div>
                    <div className={classNames.messagePanelHeaderTextContainer}>
                        <div className={classNames.messagePanelHeaderText}>{t('Archive to 360')}</div>
                        <div className={classNames.messagePanelHeaderSubText}>{t('Files will be stored on the document card')}</div>
                    </div>
                    <div className={classNames.messagePanelHeaderCloseIcon}>
                        <IconButton iconProps={{ iconName: 'Cancel' }} title={t('Close')} ariaLabel={t('Close')} onClick={closePanel} />
                    </div>
                </div>
            </div>
        ), [closePanel]);

    const onRenderFooterContent: IRenderFunction<IPanelProps> = useCallback(
        (props, defaultRender) => (
            <div style={{ display: 'flex', flexDirection: 'row', columnGap: '10px', justifyContent: 'flex-end' }}>
                <DefaultButton text={t("Cancel")} onClick={closePanel} />
                <PrimaryButton disabled={isSavingInProgress} text={isDocumentSearch ? t("Save to 360") : t("Continue")} onClick={onClick} />
            </div>
        ),
        [closePanel, isDocumentSearch, isSavingInProgress, onClick]
    );

    const getSearchCriteria = useCallback((searchCriteria: EnumSearchCriteria) => {
        setIsDocumentSearch(searchCriteria === EnumSearchCriteria.Document);
    }, []);

    const getCaseDocumentData = useCallback((caseDocumentData: ICaseDocumentRequest) => {
        setExistingCaseDocumentProps(caseDocumentData);
    }, []);

    return <Panel type={PanelType.custom} customWidth="418px" isOpen={props.isOpen} onDismiss={closePanel} closeButtonAriaLabel={t('Close')}
        styles={{ content: { marginTop: '-10px' } }} isFooterAtBottom={true} onRenderNavigation={onRenderNavigationContent} onRenderFooterContent={onRenderFooterContent}>
        <MessageCaseView {...props} isFileArchiveAction={props.isOpen} caseDocumentData={undefined} getSearchCriteria={getSearchCriteria}
            getCaseDocumentData={getCaseDocumentData} />
    </Panel>;
};

export default ChatArchivePanel;