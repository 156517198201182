import { FC, useCallback, useEffect, useState } from "react";
import { ICommonProps } from "../../ICommonProps";
import { classNames, dropdownStyles } from "../../../styles/MergeStyleSets";
import { Dropdown, IDropdownOption, TextField } from "@fluentui/react";
import { t } from "i18next";
import { ICaseDocumentRequest } from "../../../model/model";
import { Constants } from "../../../helpers/Constants";
import { FileType } from "../../../helpers/FileType";

interface IMessageNewCaseViewProps extends ICommonProps {
    caseDocumentData: ICaseDocumentRequest;
}

export const MessageNewCaseView: FC<IMessageNewCaseViewProps> = (props) => {
    const [newCaseDocumentProps, setNewCaseDocumentProps] = useState<ICaseDocumentRequest>({ ...props.caseDocumentData, caseTitle: FileType.removeExtensionFromFileName(props.caseDocumentData.caseTitle)?.substring(0, Constants.CaseTitleMaxLength) });
    const [accessGroupOptions, setAccessGroupOptions] = useState<IDropdownOption[]>([]);
    const [showCaseTitleError, setShowCaseTitleError] = useState(false);
    const [selectedAccessGroup, setSelectedAccessGroup] = useState<number>();
    const [showAccessGroupError, setShowAccessGroupError] = useState(false);
    const requiredError = t('Required');

    const onCaseTitleChange = useCallback((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setNewCaseDocumentProps(previousState => ({ ...previousState, caseTitle: newValue }));
        if (newValue?.trim() === "")
            setShowCaseTitleError(true);
        else
            setShowCaseTitleError(false);
    }, []);

    const onAccessGroupChange = useCallback((event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): void => {
        setNewCaseDocumentProps(previousState => ({ ...previousState, accessGroup: option?.text }));
        setSelectedAccessGroup(Number(option?.key));
        setShowAccessGroupError(false);
    }, []);

    const getAccessGroups = useCallback(() => {
        setAccessGroupOptions([]);
        props.dataProvider?.P360?.getAccessGroups().then((res) => {
            for (const element of res.data)
                setAccessGroupOptions(accessGroup => [...accessGroup, { key: element.Recno, text: element.Code }]);

            if (typeof props.caseDocumentData?.accessGroup !== 'undefined' && props.caseDocumentData?.accessGroup !== "")
                setSelectedAccessGroup(res.data.find(ag => ag.Code === props.caseDocumentData?.accessGroup)?.Recno);
        });
    }, [props.dataProvider?.P360, props.caseDocumentData?.accessGroup]);

    useEffect(() => {
        getAccessGroups();
    }, [getAccessGroups]);
    return <div className={classNames.messageNewCaseViewContainer}>
        <div>
            <TextField label={t('Case title')}
                value={newCaseDocumentProps.caseTitle}
                required
                multiline
                maxLength={Constants.CaseTitleMaxLength}
                resizable={false}
                onChange={onCaseTitleChange}
                errorMessage={showCaseTitleError ? requiredError : undefined} />
        </div>
        <div>
            <Dropdown placeholder={t('Select an option')}
                required
                selectedKey={selectedAccessGroup}
                label={t('Access group')}
                options={accessGroupOptions}
                styles={dropdownStyles}
                onChange={onAccessGroupChange}
                errorMessage={showAccessGroupError ? requiredError : undefined} />
        </div>
    </div>;
};