import { FC, useCallback, useState } from "react";
import { ICommonProps } from "../../ICommonProps";
import { classNames } from "../../../styles/MergeStyleSets";
import Searchbox from "../../common/Searchbox";
import { EnumSearchCriteria, ICaseDocumentRequest, IDocumentResult, ISearchCase } from "../../../model/model";

interface IMessageExistingCaseViewProps extends ICommonProps {
    selectedCase: string;
    selectedDocument?: string;
    caseDocumentData: ICaseDocumentRequest;
    archiveOnExistingDocumentEnabled: boolean;
    getSearchCriteria(searchCriteria: EnumSearchCriteria): void;
    getCaseDocumentData(caseDocumentData: ICaseDocumentRequest): void;
}

export const MessageExistingCaseView: FC<IMessageExistingCaseViewProps> = (props) => {
    const [searchErrorMessage, setSearchErrorMessage] = useState<string | undefined>(undefined);
    const [searchCaseCriteria] = useState<ISearchCase>(() => {
        if (typeof props.caseDocumentData?.caseNumber != 'undefined' && props.caseDocumentData?.caseNumber) {
            return { searchCriteria: props.caseDocumentData?.caseNumber, includeClosedCases: false };
        }

        if (typeof props.caseDocumentData?.documentNumber != 'undefined' && props.caseDocumentData?.documentNumber) {
            return { searchCriteria: props.caseDocumentData?.documentNumber, includeClosedCases: false };
        }

        return { searchCriteria: props.selectedCase ?? props.selectedDocument, includeClosedCases: false };
    });
    const searchCriteriaOptions = props.archiveOnExistingDocumentEnabled ? EnumSearchCriteria.All : EnumSearchCriteria.Case;
    const searchBoxWidth = props.archiveOnExistingDocumentEnabled ? 230 : 245;

    const onSearch = useCallback((caseOrDocumentNumber: string, searchCriteria: EnumSearchCriteria, data?: IDocumentResult) => {
        if (caseOrDocumentNumber !== "") {
            setSearchErrorMessage(undefined);
        }
        if (searchCriteria === EnumSearchCriteria.Document) {
            props.getCaseDocumentData({ ...props.caseDocumentData, documentNumber: caseOrDocumentNumber });
        }
    }, [props]);

    const onSearchOptionChange = useCallback((searchCriteria: EnumSearchCriteria) => {
        props.getSearchCriteria(searchCriteria);
    }, [props]);

    return <div className={classNames.messageExistingCaseViewContainer}>
        <div>
            <Searchbox {...props}
                enumSearchCriteria={searchCriteriaOptions}
                includeClosedCases={false}
                searchText={searchCaseCriteria.searchCriteria}
                getDocumentsHandler={onSearch}
                searchBoxWidth={searchBoxWidth}
                searchErrorMessage={searchErrorMessage}
                onSearchOptionChange={onSearchOptionChange} />
        </div>
    </div>;
};