import { FC, useCallback, useState } from "react";
import { Features } from "../../config/Features";
import { classNames } from "../../styles/MergeStyleSets";
import { MessageBar, MessageBarType, ScrollablePane, ScrollbarVisibility, Sticky, StickyPositionType, ThemeProvider } from "@fluentui/react";
import ErrorNotification from "../error/ErrorNotification";
import ChatList from "./ChatList";
import { ICommonProps } from "../ICommonProps";
import ChatCommandBar from "./ChatCommandBar";
import { FetchUserProfilePhotoRequestParameter, IDocumentResponse, IMessageItem, ITeamRequest } from "../../model/model";
import ChatArchivePanel from "./panel/ChatArchivePanel";
import { useTranslation } from "react-i18next";
import { useBoolean } from "@fluentui/react-hooks";
import FilterPanel from "../Filter/FilterPanel";
import { useTeams } from "../../context/TeamsContext";
import { MapTeamsContextToITeamRequest } from "../../helpers/Mapper";
import { useMessages } from "../../context/MessagesContext";
import { MessagesActionType } from "../../context/MessagesReducer";

interface IChatArchivingProps extends ICommonProps {
    // use only for testing purposes
    skipViewportMeasures?: boolean;
}

const ChatArchiving: FC<IChatArchivingProps> = (props) => {
    const { t } = useTranslation();
    const { teamsState } = useTeams();
    const { messageState, dispatch } = useMessages();
    const [successMessage, setSuccessMessage] = useState<string>('');
    const [selectedMessages, setSelectedMessages] = useState<IMessageItem[]>([]);
    const [isArchivePanelOpen, { setTrue: openArchivePanel, setFalse: closeArchivePanel }] = useBoolean(false);
    const [isFilterPanelOpen, { setTrue: openFilterPanel, setFalse: closeFilterPanel }] = useBoolean(false);
    const [isProfilePhotoLoading, { setTrue: showProfilePhotoShimmer, setFalse: hideProfilePhotoShimmer }] = useBoolean(false);
    const [isProfilePhotoVisible, { setTrue: showProfilePhoto, setFalse: hideProfilePhoto }] = useBoolean(false);
    const [isAttachmentVisible, { setTrue: showAttachments, setFalse: hideAttachments }] = useBoolean(true);

    const clearSelection = useCallback(() => {
        setSelectedMessages([]);
    }, []);

    const onArchiveHandler = useCallback(() => {
        openArchivePanel();
    }, [openArchivePanel]);

    const onFilterHandler = useCallback(() => {
        openFilterPanel();
    }, [openFilterPanel]);

    //TODO: Error handling required
    const profilePhotoHandler = useCallback(async (checked?: boolean) => {
        if (checked) {
            showProfilePhotoShimmer();
            showProfilePhoto();
            const token = await teamsState.getAccessToken();
            const request: FetchUserProfilePhotoRequestParameter = {
                AccessToken: token,
                TeamRequest: MapTeamsContextToITeamRequest(teamsState.userContext) as ITeamRequest,
                Members: messageState.conversationMembers
            };
            props.dataProvider?.P360.fetchUserProfilePhotos(request).then((response) => {
                if (response && response.data.length > 0) {
                    dispatch({ type: MessagesActionType.UpdateProfilePhoto, payload: { conversationMembers: response.data } });
                    hideProfilePhotoShimmer();
                }
            }).catch(() => {
                hideProfilePhotoShimmer();
                hideProfilePhoto();
            });
        }
        else {
            const members = [...messageState.conversationMembers];
            members.forEach(member => {
                member.Photo = '';
            });
            dispatch({ type: MessagesActionType.UpdateProfilePhoto, payload: { conversationMembers: members } });
            hideProfilePhotoShimmer();
            hideProfilePhoto();
        }

    }, [dispatch, hideProfilePhoto, hideProfilePhotoShimmer, messageState.conversationMembers, props.dataProvider?.P360, showProfilePhoto, showProfilePhotoShimmer, teamsState]);

    const attachmentHandler = useCallback((checked?: boolean) => {
        checked ? showAttachments() : hideAttachments();
    }, [hideAttachments, showAttachments]);

    const onSaveComplete = useCallback((response: IDocumentResponse) => {
        setSuccessMessage(`${t('Chat messages archived successfully')}. ${t('Total attachments:')} ${response.TotalAttachmentCount}, ${t('Total attachments archived:')} ${response.TotalAttachmentArchivedCount}`);
    }, [t]);

    const onSuccessMessageDismiss = useCallback(() => {
        setSuccessMessage('');
    }, []);

    if (Features.EnableChatArchiving) {
        return <ThemeProvider className={classNames.wrapper}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} style={{ overflowX: "hidden" }}>
                <Sticky stickyPosition={StickyPositionType.Header}>
                    <ErrorNotification />
                    {successMessage &&
                        <MessageBar messageBarType={MessageBarType.success} isMultiline={false} onDismiss={onSuccessMessageDismiss} dismissButtonAriaLabel="Close">
                            {successMessage}
                        </MessageBar>
                    }
                    <ChatCommandBar selectedMessages={selectedMessages} archiveHandler={onArchiveHandler} filterHandler={onFilterHandler}
                        clearSelection={clearSelection} profilePhotoHandler={profilePhotoHandler} attachmentHandler={attachmentHandler} />
                </Sticky>
                <ChatList {...props} showPhotoShimmer={isProfilePhotoLoading} showProfilePhoto={isProfilePhotoVisible}
                    setSelectedMessages={setSelectedMessages} selectedMessages={selectedMessages} includeAttachments={isAttachmentVisible} />
            </ScrollablePane>
            <ChatArchivePanel {...props} selectedMessages={selectedMessages} isOpen={isArchivePanelOpen} onSaveComplete={onSaveComplete}
                onDismiss={closeArchivePanel} includeAttachments={isAttachmentVisible} />
            <FilterPanel isOpen={isFilterPanelOpen} isProfilePhotoVisible={isProfilePhotoVisible} onDismiss={closeFilterPanel} />
        </ThemeProvider>;
    } else {
        return <blockquote className={classNames.errorBlockQuote}>
            <h3>{t('Teams Chat Archiving feature is disabled')}</h3>
        </blockquote>;
    }
};

export default ChatArchiving;