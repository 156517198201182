import { FC, useState } from 'react';
import { DatePicker, Icon } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

interface IDateRangeFilterProps {
    fromDate?: Date;
    toDate?: Date;
    onDateSelection: (fromDate: Date | null | undefined, toDate: Date | null | undefined) => void;
}

const DateRangeFilter: FC<IDateRangeFilterProps> = (props) => {
    const { t } = useTranslation();
    const [selectedFromDate, setSelectedFromDate] = useState<Date | undefined>(props.fromDate as Date);
    const [selectedToDate, setSelectedToDate] = useState<Date | undefined>(props.toDate as Date);

    const onSelectFromDate = (date: Date | null | undefined) => {
        date ? setSelectedFromDate(date) : setSelectedFromDate(undefined);
        props.onDateSelection(date, selectedToDate);
    };

    const onSelectToDate = (date: Date | null | undefined) => {
        date ? setSelectedToDate(date) : setSelectedToDate(undefined);
        props.onDateSelection(selectedFromDate, date);
    };

    return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <DatePicker
            label={t("From date")}
            placeholder={t("Select a date")}
            ariaLabel="Select a date"
            style={{ width: '140px' }}
            allowTextInput={true}
            value={selectedFromDate}
            onSelectDate={onSelectFromDate}
            maxDate={new Date()}
        />
        <Icon iconName='Forward' style={{ paddingTop: '35px' }} />
        <DatePicker
            label={t("To date")}
            placeholder={t("Select a date")}
            ariaLabel="Select a date"
            style={{ width: '140px' }}
            allowTextInput={true}
            value={selectedToDate}
            minDate={selectedFromDate}
            maxDate={new Date()}
            onSelectDate={onSelectToDate}
        />
    </div>;
};

export default DateRangeFilter;