import { IConversationMember, IMessageItem } from "../model/model";
export enum MessagesActionType {
    RefreshMessagesByApi = "REFRESH_MESSAGES_BY_API",
    RefreshMessagesByFilter = "REFRESH_MESSAGES_BY_FILTER",
    UpdateProfilePhoto = "UPDATE_PROFILE_PHOTO"
}

export interface IMessagesState {
    initialMessages: IMessageItem[];
    messages: IMessageItem[];
    conversationTitle: string;
    conversationMembers: IConversationMember[];
}

export interface IMessagesPayload {
    data?: IMessageItem[];
    conversationTitle?: string;
    conversationMembers?: IConversationMember[];
}

export interface IMessagesAction {
    type: MessagesActionType;
    payload?: IMessagesPayload
}

function MessagesReducer(state: IMessagesState, action: IMessagesAction): IMessagesState {
    const newState = { ...state };
    switch (action.type) {
        case MessagesActionType.RefreshMessagesByApi: {
            newState.messages = action.payload?.data ?? [];
            newState.initialMessages = action.payload?.data ?? [];
            newState.conversationTitle = action.payload?.conversationTitle ?? '';
            newState.conversationMembers = action.payload?.conversationMembers ?? [];
            return newState;
        }
        case MessagesActionType.RefreshMessagesByFilter: {
            newState.messages = action.payload?.data ?? [];
            return newState;
        }
        case MessagesActionType.UpdateProfilePhoto: {
            newState.conversationMembers = action.payload?.conversationMembers ?? [];
            return newState;
        }
        default:
            return state;
    }
}

export default MessagesReducer;