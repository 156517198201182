import { createContext, Dispatch, useContext, useEffect, useMemo, useReducer } from "react";
import { useTeams } from "./TeamsContext";
import { ITeamServiceRequest } from "../model/model";
import { mapMessageToMessageItem, MapTeamsContextToITeamRequest } from "../helpers/Mapper";
import { DataProvider } from "../providers/DataProvider";
import MessagesReducer, { IMessagesAction, IMessagesState, MessagesActionType } from "./MessagesReducer";

export interface IMessagesContextType {
    messageState: IMessagesState;
    dispatch: Dispatch<IMessagesAction>;
}

interface IMessagesContextProviderProps {
    children: React.ReactElement;
    dataProvider: DataProvider;
}

const MessagesContext = createContext<IMessagesContextType | undefined>(undefined);

const MessagesContextProvider = (props: Readonly<IMessagesContextProviderProps>): JSX.Element => {
    const { teamsState } = useTeams();
    const [messageState, dispatch] = useReducer(MessagesReducer, { initialMessages: [], messages: [], conversationTitle: '', conversationMembers: [] });

    useEffect(() => {
        async function getMessages() {
            const token = await teamsState.getAccessToken();
            const request: ITeamServiceRequest = {
                accessToken: token,
                teamRequest: MapTeamsContextToITeamRequest(teamsState.userContext),
                nextLinkUrl: ''
            };
            const response = await props.dataProvider?.P360.getChatMessages(request);
            if (response && response.data.Messages.length > 0) {
                const messages = mapMessageToMessageItem(response.data.Messages);
                dispatch({
                    type: MessagesActionType.RefreshMessagesByApi,
                    payload: { data: messages, conversationTitle: response.data.Title, conversationMembers: response.data.Members }
                });
            }
        }
        getMessages();
    }, [props.dataProvider?.P360, teamsState]);

    const messagesContext: IMessagesContextType = useMemo(() => ({
        messageState: messageState,
        dispatch: dispatch
    }), [messageState]);

    return (
        <MessagesContext.Provider value={messagesContext}>
            {props.children}
        </MessagesContext.Provider>
    );
};

function useMessages() {
    const context = useContext(MessagesContext);
    if (context === undefined) {
        throw new Error('useMessages must be used within a MessagesProvider');
    }
    return context;
}

export { MessagesContext, MessagesContextProvider, useMessages };