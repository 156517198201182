import { Link } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { FC } from "react";

interface IReadMoreProps {
    content?: string
}

const ReadMore: FC<IReadMoreProps> = (props) => {
    const [readMore, { setTrue: showMore, setFalse: showLess }] = useBoolean(false);
    if (readMore) {
        return <div style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
            <span dangerouslySetInnerHTML={{ __html: props.content as string }}></span>
            <Link onClick={showLess}>see less</Link>
        </div>;
    }
    if (props.content && props.content.length > 200) {
        return <>
            <span dangerouslySetInnerHTML={{ __html: props.content.substring(0, 200) }}></span>
            <Link onClick={showMore}>see more</Link>
        </>;
    }
    return <span dangerouslySetInnerHTML={{ __html: props.content as string }}></span>;
};

export default ReadMore;