import { FC } from "react";
import { IMessageItem } from "../../model/model";
import { classNames } from "../../styles/MergeStyleSets";
import { Shimmer, ShimmerElementType } from "@fluentui/react";

interface IProfilePhotoProps {
    item: IMessageItem;
    showProfilePhoto?: boolean;
    showPhotoShimmer?: boolean;
}

const ProfilePhoto: FC<IProfilePhotoProps> = (props) => {
    if (props.showPhotoShimmer) {
        return <div className={classNames.profilePhotoInitialsContainer}>
            <span className={classNames.profilePhotoInitials}>
                <Shimmer shimmerElements={[{ type: ShimmerElementType.circle, height: 32 }]} />
            </span>
            <span style={{ paddingTop: '8px' }}>{props.item.fromUser}</span>
        </div>;
    }

    if (props.showProfilePhoto && props.item.fromUserPhoto) {
        return <div className={classNames.profilePhotoInitialsContainer}>
            <span>
                <img className={classNames.profilePhotoInitials} src={`data:image/png;base64,${props.item.fromUserPhoto}`} alt={props.item.fromUser} />
            </span>
            <span style={{ paddingTop: '8px' }}>{props.item.fromUser}</span>
        </div>;
    }
    else if (props.item.fromUser) {
        const namePart = props.item.fromUser.split(' ');
        const firstNameInitial = namePart[0] ? namePart[0][0] : "";
        const lastNameInitial = namePart[1] ? namePart[1][0] : "";
        return <div className={classNames.profilePhotoInitialsContainer}>
            <span className={classNames.profilePhotoInitials}>
                {firstNameInitial}
                {lastNameInitial}
            </span>
            <span style={{ paddingTop: '8px' }}>{props.item.fromUser}</span>
        </div>;
    }

    return <></>;
};

export default ProfilePhoto;