import { useState, FC, useEffect, useCallback } from 'react';
import { Stack, DefaultButton, Dropdown, IDropdownOption, PrimaryButton, TextField } from '@fluentui/react';
import { buttonStyles, verticalGapStackTokens } from '../../styles/MergeStyleSets';
import { ICommonProps } from '../ICommonProps';
import { EnumDocumentFieldsError, EnumUIConfiguration, ICaseDocumentRequest, ITeamServiceRequest } from '../../model/model';
import { useTranslation } from 'react-i18next';
import { FileType } from '../../helpers/FileType';
import { Constants } from '../../helpers/Constants';
import { sortData } from '../../helpers/Mapper';

interface IDocumentViewProps extends ICommonProps {
    caseDocumentData: ICaseDocumentRequest,
    showDocumentTitleField: boolean,
    uiConguration: EnumUIConfiguration,
    saveClickHandler(caseData: ICaseDocumentRequest | undefined): void,
    previousClickHandler(caseData: ICaseDocumentRequest | undefined): void
}

const DocumentView: FC<IDocumentViewProps> = (props) => {
    const { t } = useTranslation();
    const [newCaseDocumentProps, setNewCaseDocumentProps] = useState<ICaseDocumentRequest>({ ...props.caseDocumentData, documentTitle: FileType.removeExtensionFromFileName(props.caseDocumentData.documentTitle)?.substring(0, Constants.DocumentTitleMaxLength) });
    const [recordTypeOptions, setRecordTypeOptions] = useState<IDropdownOption[]>([]);
    const [actionTypeOptions, setActionTypeOptions] = useState<IDropdownOption[]>([]);
    const [recordTypeFromActionTypeOptions, setRecordTypeFromActionTypeOptions] = useState<IDropdownOption[]>([]);
    const [showDocumentTitleError, setShowDocumentTitleError] = useState(false);
    const [selectedPublicityClass, setSelectedPublicityClass] = useState<number>();
    const [publicityClassOption, setPublicityClassOption] = useState<IDropdownOption[]>([]);
    const [showRecordTypeError, setShowRecordTypeError] = useState(false);
    const [recordTypeErrorMessage, setRecordTypeErrorMessage] = useState<string>();
    const [publicityClassErrorMessage, setPublicityClassErrorMessage] = useState<string>();
    const [showPublicityClassError, setShowPublicityClassError] = useState(false);
    const [showActionTypeError, setShowActionTypeError] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const documentCategoryError = t('This record type can not be created from Teams because the connected document category is not supported');
    const defaultAccessCodeError = t('This record type can not be created from Teams because the connected access code is not public');
    const publicityClassError = t('The selected Publicity class cannot be used for document creation');
    const personalDataError = t('Personal data does not have a value');
    const requiredError = t('Required');
    const zeroRecno = 0;
    const [defaultRecordType] = useState(() => {
        if (typeof props.caseDocumentData?.recordTypeRecno != 'undefined' && props.caseDocumentData?.recordTypeRecno)
            return props.caseDocumentData?.recordTypeRecno;
        else
            return undefined;
    });

    const [defaultActionType] = useState(() => {
        if (typeof props.caseDocumentData?.actionTypeRecno != 'undefined' && props.caseDocumentData?.actionTypeRecno)
            return props.caseDocumentData?.actionTypeRecno;
        else
            return undefined;
    });

    const setErrorOnRecordType = useCallback((error: boolean, recordTypeError?: EnumDocumentFieldsError) => {
        if (error) {
            setShowRecordTypeError(true);
            switch (recordTypeError) {
                case EnumDocumentFieldsError.Required:
                    setRecordTypeErrorMessage(requiredError);
                    break;
                case EnumDocumentFieldsError.AccessCode:
                    setRecordTypeErrorMessage(defaultAccessCodeError);
                    break;
                case EnumDocumentFieldsError.DocumentCategory:
                    setRecordTypeErrorMessage(documentCategoryError);
                    break;
                case EnumDocumentFieldsError.PersonalData:
                    setRecordTypeErrorMessage(personalDataError);
                    break;
            }
        }
        else {
            setShowRecordTypeError(false);
            setRecordTypeErrorMessage("");
        }
    }, [defaultAccessCodeError, documentCategoryError, personalDataError, requiredError]);

    const setErrorOnPublicityClass = useCallback((error: boolean, publicityError?: EnumDocumentFieldsError) => {
        if (error) {
            setShowPublicityClassError(true);
            switch (publicityError) {
                case EnumDocumentFieldsError.Required:
                    setPublicityClassErrorMessage(requiredError);
                    break;
                case EnumDocumentFieldsError.PublicityClass:
                    setPublicityClassErrorMessage(publicityClassError);
                    break;
            }
        }
        else {
            setShowPublicityClassError(false);
            setPublicityClassErrorMessage("");
        }
    }, [publicityClassError, requiredError]);

    const getRecordTypes = useCallback(() => {
        const teamRequest: ITeamServiceRequest = { caseDocumentRequest: props.caseDocumentData };
        props.dataProvider?.P360?.getRecordTypes(teamRequest).then((res) => {
            setRecordTypeOptions([]);
            if (res.data) {
                res.data = [...res.data].sort(sortData);
                for (const element of res.data) {
                    setRecordTypeOptions(recordType => [...recordType, { key: element.Recno, text: element.CodeAndDescription, data: element }]);
                }
            }
        });
    }, [props.caseDocumentData, props.dataProvider?.P360]);

    const getRecordTypesBasedOnActionType = useCallback((actionTypeRecno: number) => {
        const caseDocumentData = { ...props.caseDocumentData };
        caseDocumentData.actionTypeRecno = actionTypeRecno;
        const teamRequest: ITeamServiceRequest = { caseDocumentRequest: caseDocumentData };
        props.dataProvider?.P360?.getRecordTypesBasedOnActionType(teamRequest).then((res) => {
            setRecordTypeOptions([]);
            setRecordTypeFromActionTypeOptions([]);
            if (res.data) {
                res.data = [...res.data].sort(sortData);
                for (const element of res.data) {
                    setRecordTypeFromActionTypeOptions(recordType => [...recordType, { key: element.Recno, text: element.CodeAndDescription, data: element }]);
                }
            }
        });
    }, [props.caseDocumentData, props.dataProvider?.P360]);

    const getActionTypes = useCallback(() => {
        const teamRequest: ITeamServiceRequest = { caseDocumentRequest: props.caseDocumentData };
        props.dataProvider?.P360?.getActionTypes(teamRequest).then((res) => {
            setActionTypeOptions([]);
            if (res.data) {
                res.data = [...res.data].sort(sortData);
                for (const element of res.data)
                    setActionTypeOptions(actionType => [...actionType, { key: element.Recno, text: element.CodeAndDescription, data: element }]);

                if (typeof props.caseDocumentData?.actionTypeRecno != 'undefined' && props.caseDocumentData?.actionTypeRecno > zeroRecno)
                    getRecordTypesBasedOnActionType(props.caseDocumentData?.actionTypeRecno);
            }
        });
    }, [props.caseDocumentData, props.dataProvider?.P360, getRecordTypesBasedOnActionType]);

    const getPublicityClasses = useCallback(() => {
        props.dataProvider?.P360?.getPublicityClasses().then((res) => {
            for (const element of res.data)
                setPublicityClassOption(publicityClass => [...publicityClass, { key: element.Recno, text: element.Code, data: element }]);

            if (typeof props.caseDocumentData?.documentPublicityClassRecno != 'undefined' && props.caseDocumentData?.documentPublicityClassRecno > zeroRecno)
                setSelectedPublicityClass(props.caseDocumentData?.documentPublicityClassRecno);
        });
    }, [props.dataProvider?.P360, props.caseDocumentData?.documentPublicityClassRecno]);

    const onDocumentTitleChange = useCallback((_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setNewCaseDocumentProps(previousState => ({ ...previousState, documentTitle: newValue }));
        if (newValue?.trim() === "")
            setShowDocumentTitleError(true);
        else
            setShowDocumentTitleError(false);
    }, []);

    const onRecordTypesChange = useCallback((_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number): void => {
        if (!option?.data?.IsDocumentCategorySupportable)
            setErrorOnRecordType(true, EnumDocumentFieldsError.DocumentCategory);
        else if (!option?.data?.IsAccessCodeSupportable)
            setErrorOnRecordType(true, EnumDocumentFieldsError.AccessCode);
        else
            setErrorOnRecordType(false);
        setNewCaseDocumentProps(previousState => ({ ...previousState, recordTypeRecno: Number(option?.key) }));
    }, [setErrorOnRecordType]);

    const onActionTypesChange = useCallback((_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number): void => {
        setNewCaseDocumentProps(previousState => ({ ...previousState, actionTypeRecno: Number(option?.key) }));
        setShowActionTypeError(false);
        getRecordTypesBasedOnActionType(Number(option?.key));
    }, [getRecordTypesBasedOnActionType]);

    const onRecordTypeFromActionTypeOptions = useCallback((_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number): void => {
        if (!option?.data?.IsDocumentCategorySupportable)
            setErrorOnRecordType(true, EnumDocumentFieldsError.DocumentCategory);
        else if (!option?.data?.IsAccessCodeSupportable)
            setErrorOnRecordType(true, EnumDocumentFieldsError.AccessCode);
        else if (option?.data?.PersonalDataRecno === zeroRecno)
            setErrorOnRecordType(true, EnumDocumentFieldsError.PersonalData);
        else
            setErrorOnRecordType(false);
        setSelectedPublicityClass(option?.data?.PublicityClass.Recno);
        if (option?.data?.PublicityClass.Recno > zeroRecno)
            setErrorOnPublicityClass(false);
        setNewCaseDocumentProps(previousState => ({ ...previousState, recordTypeRecno: Number(option?.key), documentPublicityClassRecno: option?.data?.PublicityClass.Recno }));
    }, [setErrorOnPublicityClass, setErrorOnRecordType]);

    const onPublicityClassChange = useCallback((_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number): void => {
        if (!option?.data?.IsSupportable)
            setErrorOnPublicityClass(true, EnumDocumentFieldsError.PublicityClass);
        else
            setErrorOnPublicityClass(false);
        setSelectedPublicityClass(Number(option?.key));
        setNewCaseDocumentProps(previousState => ({ ...previousState, documentPublicityClassRecno: Number(option?.key) }));
    }, [setErrorOnPublicityClass]);

    const validateInputs = useCallback((): boolean => {
        let isValid = true;
        if (newCaseDocumentProps.documentTitle?.trim() === "") {
            setShowDocumentTitleError(true);
            isValid = false;
        }
        if (props.uiConguration === EnumUIConfiguration.ProcessView || props.uiConguration === EnumUIConfiguration.Sahke2) {
            if (typeof newCaseDocumentProps.recordTypeRecno === 'undefined' || newCaseDocumentProps.recordTypeRecno === zeroRecno) {
                setErrorOnRecordType(true, EnumDocumentFieldsError.Required);
                isValid = false;
            }
        }
        if (props.uiConguration === EnumUIConfiguration.Sahke2) {
            if (typeof newCaseDocumentProps.actionTypeRecno === 'undefined' || newCaseDocumentProps.actionTypeRecno === zeroRecno) {
                setShowActionTypeError(true);
                isValid = false;
            }
            if (typeof newCaseDocumentProps.documentPublicityClassRecno === 'undefined' || newCaseDocumentProps.documentPublicityClassRecno === zeroRecno) {
                setErrorOnPublicityClass(true, EnumDocumentFieldsError.Required);
                isValid = false;
            }
        }
        return (showDocumentTitleError === false && showActionTypeError === false && showRecordTypeError === false && showPublicityClassError === false && isValid);

    }, [newCaseDocumentProps.actionTypeRecno, newCaseDocumentProps.documentPublicityClassRecno, newCaseDocumentProps.documentTitle, newCaseDocumentProps.recordTypeRecno, props.uiConguration, setErrorOnPublicityClass, setErrorOnRecordType, showActionTypeError, showDocumentTitleError, showPublicityClassError, showRecordTypeError]);

    const saveClickHandler = useCallback(() => {
        if (validateInputs()) {
            setDisabledButton(true);
            props.saveClickHandler(newCaseDocumentProps);
        }
    }, [newCaseDocumentProps, props, validateInputs]);

    const previousClickHandler = useCallback(() => {
        if (showActionTypeError === false && showRecordTypeError === false && showPublicityClassError === false)
            props.previousClickHandler(newCaseDocumentProps);
    }, [newCaseDocumentProps, props, showActionTypeError, showPublicityClassError, showRecordTypeError]);

    useEffect(() => {
        if (props.uiConguration === EnumUIConfiguration.Sahke2) {
            getActionTypes();
            getPublicityClasses();
        }
        if (props.uiConguration === EnumUIConfiguration.ProcessView) {
            getRecordTypes();
        }
        if (!props.showDocumentTitleField) {
            setNewCaseDocumentProps({ ...props.caseDocumentData, documentTitle: FileType.removeExtensionFromFileName(props.caseDocumentData.documentTitle)?.substring(0, Constants.DocumentTitleMaxLength) });
        }
    }, [getActionTypes, getRecordTypes, getPublicityClasses, props.uiConguration, props.showDocumentTitleField, props.caseDocumentData]);

    const getErrorMessage = (showError: boolean, errorMessage?: string): string | undefined => {
        return showError ? errorMessage : undefined;
    };

    return <Stack tokens={verticalGapStackTokens}>
        {
            props.showDocumentTitleField === true ?
                <Stack.Item>
                    <span>
                        <TextField label={t('Document title')}
                            value={newCaseDocumentProps.documentTitle}
                            maxLength={Constants.DocumentTitleMaxLength}
                            required
                            multiline
                            resizable={false}
                            onChange={onDocumentTitleChange}
                            errorMessage={getErrorMessage(showDocumentTitleError, requiredError)} />
                    </span>
                </Stack.Item>
                :
                <></>
        }
        {
            props.uiConguration === EnumUIConfiguration.ProcessView ?
                <Stack.Item>
                    <span>
                        <Dropdown placeholder={t('Select an option')}
                            required
                            defaultSelectedKey={defaultRecordType}
                            label={t('Record type')}
                            options={recordTypeOptions}
                            onChange={onRecordTypesChange}
                            errorMessage={getErrorMessage(showRecordTypeError, recordTypeErrorMessage)} />
                    </span>
                </Stack.Item>
                :
                <></>
        }
        {
            props.uiConguration === EnumUIConfiguration.Sahke2 ?
                <>
                    <Stack.Item>
                        <span>
                            <Dropdown placeholder={t('Select an option')}
                                required
                                defaultSelectedKey={defaultActionType}
                                label={t('Action')}
                                options={actionTypeOptions}
                                onChange={onActionTypesChange}
                                errorMessage={getErrorMessage(showActionTypeError, requiredError)} />
                        </span>
                    </Stack.Item>
                    <Stack.Item>
                        <span>
                            <Dropdown placeholder={t('Select an option')}
                                required
                                defaultSelectedKey={defaultRecordType}
                                label={t('Record type')}
                                options={recordTypeFromActionTypeOptions}
                                onChange={onRecordTypeFromActionTypeOptions}
                                errorMessage={getErrorMessage(showRecordTypeError, recordTypeErrorMessage)} />
                        </span>
                    </Stack.Item>
                    <Stack.Item>
                        <span>
                            <Dropdown placeholder={t('Select an option')}
                                required
                                selectedKey={selectedPublicityClass}
                                label={t('Publicity class')}
                                options={publicityClassOption}
                                onChange={onPublicityClassChange}
                                errorMessage={getErrorMessage(showPublicityClassError, publicityClassErrorMessage)} />
                        </span>
                    </Stack.Item>
                </>
                :
                <></>
        }
        <Stack.Item>
            <span>
                <PrimaryButton disabled={showDocumentTitleError || showActionTypeError || showRecordTypeError || showPublicityClassError || disabledButton} onClick={saveClickHandler}
                    styles={buttonStyles}>{t('Save to 360')}</PrimaryButton>
                <DefaultButton disabled={disabledButton} onClick={previousClickHandler}>{t('Go back')}</DefaultButton>
            </span>
        </Stack.Item>
    </Stack>;
};

export default DocumentView;