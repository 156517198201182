import { Link } from "@fluentui/react";
import { FC } from "react";
import { IMessageAttachment, IMessageItem } from "../../model/model";
import { FileType } from "../../helpers/FileType";

interface IAttachmentProps {
    item: IMessageItem;
}

const renderAttachment = (attachment: IMessageAttachment) => {
    if (attachment.name) {
        const fileType = FileType.getfileType(attachment.name);
        return <div key={attachment.id} style={{ display: 'flex', flexDirection: 'row', columnGap: 5 }}>
            <img src={fileType.url} alt={fileType.docType} width={16} height={16} />
            <Link href={attachment.contentUrl} title={attachment.name} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} target="_blank">{attachment.name}</Link>
        </div>;
    }
};

const Attachments: FC<IAttachmentProps> = (props) => {
    if (props.item.attachments && props.item.attachments.length > 0) {
        return <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
            {props.item.attachments?.map((attachment, index) => (
                renderAttachment(attachment)
            ))}
        </div>;
    }
    return <></>;
};

export default Attachments;