

import { FC, useState } from 'react';
import { Icon } from '@fluentui/react';
import { classNames } from '../../styles/MergeStyleSets';

interface ICollapseProps {
    header: string;
    width?: number;
    children?: React.ReactNode;
    isOpen?: boolean;
}

const Collapse: FC<ICollapseProps> = (props) => {
    const [isOpen, setIsOpen] = useState(props.isOpen || false);

    const onCollapse = () => {
        setIsOpen(!isOpen);
    };

    return <div style={{ display: 'flex', flexDirection: 'column', width: `${props.width}px`, marginBottom: '20px' }}>
        <div className={classNames.collapseHeader} role='button' tabIndex={0} onClick={onCollapse} onKeyDown={onCollapse}>
            <div>{props.header}</div>
            <div>{isOpen ? <Icon iconName="ChevronUp" /> : <Icon iconName="ChevronDown" />}</div>
        </div>
        {isOpen ? (
            <div>
                <div className={classNames.collapseContent}>
                    {props.children}
                </div>
            </div>
        ) : null}
    </div>;
};

export default Collapse;