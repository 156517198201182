import { FC, FormEvent, useCallback, useState } from "react";
import { Constants } from "../../../helpers/Constants";
import { ICommonProps } from "../../ICommonProps";
import { useP360 } from "../../../context/P360Context";
import { useId } from '@fluentui/react-hooks';
import { EnumCase, EnumSearchCriteria, ICaseDocumentRequest } from "../../../model/model";
import { ChoiceGroup, IChoiceGroupOption, IChoiceGroupOptionProps, IRenderFunction, TooltipHost } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { classNames, tooltipHostStyles } from "../../../styles/MergeStyleSets";
import { MessageNewCaseView } from "./MessageNewCaseView";
import { MessageExistingCaseView } from "./MessageExistingCaseView";

interface MessageCaseViewProps extends ICommonProps {
    isFileArchiveAction: boolean;
    caseDocumentData?: ICaseDocumentRequest;
    getSearchCriteria(searchCriteria: EnumSearchCriteria): void;
    getCaseDocumentData(caseDocumentData: ICaseDocumentRequest): void;
}
export const MessageCaseView: FC<MessageCaseViewProps> = (props) => {
    const { p360State } = useP360();
    const { t } = useTranslation();
    const tooltipId = useId('tooltip');
    const archiveOnExistingDocumentEnabled = props.isFileArchiveAction === true &&
        p360State.backendCapabilities?.Capabilities?.includes(Constants.Capabilities.ArchiveOnExistingDocument) === true &&
        p360State.backendCapabilities?.Capabilities?.includes(Constants.Capabilities.DocumentsSearch) === true;

    const disallowCaseCreation = p360State.backendCapabilities?.Capabilities?.includes(Constants.Capabilities.DisallowCaseCreation) === true;
    const [selectedExistingCaseKey, setSelectedExistingCaseKey] = useState<EnumCase>(() => {
        if (typeof props.caseDocumentData?.selectionNewOrExisting != 'undefined' && props.caseDocumentData?.selectionNewOrExisting)
            return props.caseDocumentData?.selectionNewOrExisting;
        else
            return EnumCase.ExistingCase;
    });

    const newCaseOptionRender: IRenderFunction<IChoiceGroupOption & IChoiceGroupOptionProps> = useCallback((props, render) => {
        return (
            <TooltipHost content={t('User does not have permission to create a new case')} id={tooltipId} calloutProps={{ gapSpace: 0, hidden: !disallowCaseCreation }} styles={tooltipHostStyles}>
                {
                    render !== undefined ?
                        render(props) : null
                }
            </TooltipHost>
        );
    }, [t, tooltipId, disallowCaseCreation]);

    const onChange = useCallback((ev?: FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        switch (option?.key) {
            case '1':
                setSelectedExistingCaseKey(EnumCase.NewCase);
                break;
            case '2':
            default:
                setSelectedExistingCaseKey(EnumCase.ExistingCase);
                break;
        }
    }, []);
    const options: IChoiceGroupOption[] = [
        { key: EnumCase.NewCase.toString(), text: t('New case'), disabled: disallowCaseCreation, onRenderField: newCaseOptionRender },
        { key: EnumCase.ExistingCase.toString(), text: archiveOnExistingDocumentEnabled ? t('Existing case or document') : t('Existing case') }
    ];

    return <div className={classNames.messageCaseViewContainer}>
        <div className={classNames.messageCaseViewHeader}>{t('Case Information')}</div>
        <div>
            {archiveOnExistingDocumentEnabled ? t('Do you want to create a new case or use existing case or document?') : t('Do you want to create a new case or use existing case?')}
        </div>
        <div>
            <ChoiceGroup selectedKey={selectedExistingCaseKey} options={options} onChange={onChange} required={true} />
            {
                selectedExistingCaseKey === EnumCase.NewCase ?
                    <MessageNewCaseView {...props} caseDocumentData={{ caseTitle: "" }} />
                    :
                    <MessageExistingCaseView {...props} selectedCase={""} caseDocumentData={{ caseTitle: "" }} archiveOnExistingDocumentEnabled={archiveOnExistingDocumentEnabled} />
            }
        </div>
    </div>;
};