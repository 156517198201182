import { FC, useCallback, useEffect, useState } from 'react';
import { DefaultButton, IconButton, IPanelProps, IRenderFunction, Link, Panel, PanelType, PrimaryButton } from '@fluentui/react';
import { ICommonProps } from '../ICommonProps';
import Collapse from '../common/Collapse';
import DateRangeFilter from './DateRangeFilter';
import MultiCheckFilter from './MultiCheckFilter';
import { useTranslation } from 'react-i18next';
import { MessagesActionType } from '../../context/MessagesReducer';
import { useMessages } from '../../context/MessagesContext';
import { IMessageItem } from '../../model/model';

interface IFilterPanelProps extends ICommonProps, IPanelProps {
    isOpen: boolean;
    isProfilePhotoVisible?: boolean;
}

const FilterPanel: FC<IFilterPanelProps> = (props) => {
    const { t } = useTranslation();
    const { messageState, dispatch } = useMessages();
    const [dates, setDates] = useState<Date[]>([]);
    const [names, setNames] = useState<IMessageItem[]>([]);
    const [filteredDates, setFilteredDates] = useState<Date[]>([]);
    const [filteredNames, setFilteredNames] = useState<string[]>([]);

    const closePanel = useCallback(() => {
        props.onDismiss?.();
    }, [props]);

    const onApplyClick = useCallback(() => {
        let messages = [...messageState.initialMessages];
        if (filteredNames.length > 0) {
            messages = messages.filter(item => filteredNames.includes(item.fromUser as string));
        }
        if (filteredDates.length > 0) {
            messages = messages.filter(item => {
                if (item.lastModifiedDateTime)
                    return item.lastModifiedDateTime >= filteredDates[0] && item.lastModifiedDateTime <= filteredDates[1];
                return false;
            });
            setDates(filteredDates);
        }
        dispatch({ type: MessagesActionType.RefreshMessagesByFilter, payload: { data: messages } });
        closePanel();
    }, [closePanel, dispatch, filteredDates, filteredNames, messageState.initialMessages]);

    const onClearAll = useCallback(() => {
        setFilteredNames([]);
        const messages = [...messageState.initialMessages];
        const modifiedDates = initialMinMaxDates(messages);
        setDates([modifiedDates[0], modifiedDates[modifiedDates.length - 1]]);
        dispatch({ type: MessagesActionType.RefreshMessagesByFilter, payload: { data: messages } });
        closePanel();
    }, [closePanel, dispatch, messageState.initialMessages]);

    const onRenderNavigationContent: IRenderFunction<IPanelProps> = useCallback(
        (props, defaultRender) => (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ paddingLeft: '15px' }}>
                    <h3>Filters</h3>
                </div>
                <div style={{ paddingTop: '5px' }}>
                    <IconButton iconProps={{ iconName: 'Cancel' }} title="Close" ariaLabel="Close" onClick={closePanel} />
                </div>
            </div>
        ),
        [closePanel]
    );

    const onRenderFooterContent: IRenderFunction<IPanelProps> = useCallback(
        (props, defaultRender) => (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ paddingTop: '5px' }}>
                    <Link onClick={onClearAll}>
                        {t("Clear all")}
                    </Link>
                </div>
                <div style={{ display: 'flex', gap: 10 }}>
                    <DefaultButton text={t("Cancel")} onClick={closePanel} />
                    <PrimaryButton text={t("Apply")} onClick={onApplyClick} />
                </div>
            </div>
        ),
        [closePanel, onApplyClick, onClearAll, t]
    );

    const onCheckChanged = (item: string, checked: boolean) => {
        setFilteredNames(checked ? [...filteredNames, item] : filteredNames.filter(name => name !== item));
    };

    const onDateSelection = useCallback((fromDate: Date | null | undefined, toDate: Date | null | undefined) => {
        if (fromDate && toDate)
            setFilteredDates([fromDate, toDate]);
        else
            setFilteredDates([]);
    }, []);

    useEffect(() => {
        const messages = [...messageState.initialMessages];
        setNames(getUniqueItemsById(messages));
        const modifiedDates = initialMinMaxDates(messages);
        setDates([modifiedDates[0], modifiedDates[modifiedDates.length - 1]]);
    }, [messageState.initialMessages]);

    return <Panel type={PanelType.custom} customWidth="350px" isOpen={props.isOpen} closeButtonAriaLabel={t('Close')} onRenderNavigation={onRenderNavigationContent}
        isFooterAtBottom={true} onRenderFooterContent={onRenderFooterContent} styles={{ content: { padding: '0' }, footerInner: { paddingRight: '10px' } }}>
        <div style={{ marginTop: '20px' }}>
            <Collapse header={t('Dates')} isOpen={true}>
                <DateRangeFilter fromDate={dates[0]} toDate={dates[1]} onDateSelection={onDateSelection} />
            </Collapse>
            <Collapse header={t('Names')} isOpen={true}>
                <MultiCheckFilter items={names} selectedItems={filteredNames} showProfilePhoto={props.isProfilePhotoVisible} onCheckChanged={onCheckChanged} />
            </Collapse>
        </div>
    </Panel>;
};

const getUniqueItemsById = (items: IMessageItem[]): IMessageItem[] => {
    const uniqueItemsMap = items.reduce((map, item) => {
        if (!map.has(item.fromUserId as string)) {
            map.set(item.fromUserId as string, item);
        }
        return map;
    }, new Map<string, IMessageItem>());

    return Array.from(uniqueItemsMap.values());
};

function initialMinMaxDates(messages: IMessageItem[]): Date[] {
    return messages.map(item => item.lastModifiedDateTime as Date).sort((a, b) => a.getTime() - b.getTime());
}

export default FilterPanel;