import { mergeStyleSets, IDropdownStyles, IStackStyles, IStackTokens, DefaultPalette, IFabricStyles, mergeStyles, ITooltipHostStyles, IStackItemStyles, IDetailsListStyles, ISeparatorStyles, IGroupHeaderStyles, IScrollablePaneStyles, IComboBoxStyles, IMessageBarStyles, IButtonStyles, IProgressIndicatorStyles, getTheme, FontWeights } from '@fluentui/react';

export const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px',
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden',
            },
        },
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '16px',
        maxWidth: '16px',
    },
    textSize: {
        fontSize: '14px'
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px',
    },
    selectionDetails: {
        marginBottom: '20px',
    },
    wrapper: {
        height: '100vh',
        backgroundColor: 'white'
    },
    container: {
        overflow: 'auto',
        maxHeight: 500
    },
    overflowTextDots: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    verticalSeperator: {
        width: '0px', /* Use only border style */
        height: '100%',
        float: 'left;',
        border: '1px inset #e1e8e8' /* This is default border style for <hr> tag */
    },
    isDisabled: {
        pointerEvents: 'none',
        opacity: '0.7'
    },
    errorBlockQuote: {
        border: '1px solid #d1d1d1',
        borderLeftWidth: '6px',
        borderRadius: '4px',
        clear: 'left',
        margin: '0 auto',
        marginTop: '10px',
        minHeight: '36px',
        padding: '1rem',
        borderColor: '#dc2626',
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 0px 0.2rem, rgba(0, 0, 0, 0.14) 0px 0.2rem 0.4rem',
        width: 'fit-content'
    },
    collpaseContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    collapseHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        cursor: 'pointer',
        padding: '10px 15px 10px 15px',
        fontWeight: 'bold',
        width: '90%',
        borderBottom: '1px solid #e1e8e8',
        borderTop: '1px solid #e1e8e8'
    },
    collapseContent: {
        padding: '15px',
        fontSize: '14px',
        maxHeight: '300px',
        overflow: 'auto',
    },
    collapseContainerGridContainer: {
        display: 'grid',
        gridTemplateColumns: '25px auto 20px'
    },
    messagePanelHeaderContainer: {
        display: 'flex'
    },
    messagePanelHeader: {
        display: 'flex',
        flexDirection: 'row',
        width: '400px'
    },
    messagePanelHeaderIcon: {
        marginLeft: '10px'
    },
    messagePanelHeaderTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '15px',
        marginTop: '3px',
        width: '400px'
    },
    messagePanelHeaderText: {
        fontSize: 20,
        fontWeight: 100
    },
    messagePanelHeaderSubText: {
        fontSize: 11,
        fontStyle: 'italic'
    },
    messagePanelHeaderCloseIcon: {
        marginRight: '-15px'
    },
    messageCaseViewContainer: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
        marginTop: '20px'
    },
    messageCaseViewHeader: {
        width: '100%',
        height: '25px',
        paddingLeft: '10px',
        paddingTop: '3px',
        backgroundColor: DefaultPalette.themeLighter
    },
    messageCaseViewDescription: {

    },
    messageNewCaseViewContainer: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
        marginTop: '10px'
    },
    messageExistingCaseViewContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '10px'
    },
    profilePhotoInitialsContainer: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '10px'
    },
    profilePhotoInitials: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '32px',
        height: '32px',
        backgroundColor: '#42477a',
        color: '#ffffff',
        fontSize: '15px',
        borderRadius: '50%',
        fontWeight: '400'
    }
});
export const detailsListMargin: IFabricStyles = {
    root: {
        marginTop: '40px'
    },
    bodyThemed: {
        background: DefaultPalette.themeTertiary,
    }
};
export const stackStyles: IStackStyles = {
    root: {
        background: DefaultPalette.themeTertiary,
    },
};
export const verticalGapStackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
};
export const hoverCardGapToken: IStackTokens = {
    childrenGap: '15%',
    padding: 'm 10px'
};
export const hoverCardVerticalGapStackTokens: IStackTokens = {
    childrenGap: 5,
    padding: 10,
};
export const hoverCardHeader: IStackStyles = {
    root: {
        height: '50px'
    }
};
export const hoverCardDetail: IStackStyles = {
    root: {
        height: '190px'
    }
};
export const hoverCardFooter: IStackStyles = {
    root: {
        height: '150px'
    }
};
export const buttonStyles = { root: { marginRight: 8, marginTop: 8 } };

export const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 300 },
};
export const iconClass = mergeStyles({
    fontSize: 16,
    height: 16,
    width: 16,
    margin: '0 5px',
});
export const iconFillStyles = mergeStyleSets({
    green: [{ color: 'green' }, iconClass],
    red: [{ color: 'red' }, iconClass],
    blue: [{ color: 'blue' }, iconClass],
    yellow: [{ color: 'goldenrod' }, iconClass]
});
export const tooltipHostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };
export const itemStyles: React.CSSProperties = {
    display: 'flex',
    fontWeight: 600,
    padding: '5px 0'
};
export const archiveStatusStyleLeft: React.CSSProperties = {
    alignItems: 'flex-start',
    display: 'flex',
    height: 22,
    justifyContent: 'left',
    width: 140,
};
export const archiveStatusStyleRight: React.CSSProperties = {
    alignItems: 'flex-end',
    display: 'flex',
    height: 22,
    justifyContent: 'flex-end'
};
export const detailListStyles: Partial<IDetailsListStyles> = {
    root: {
        border: '1px solid #e1e8e8'
    }
};
export const seperatorStyle: Partial<ISeparatorStyles> = {
    content: {
        border: '1px solid #e1e8e8'
    }
};
export const viewAlignmentStackStyles: IStackStyles = {
    root: {
        marginLeft: -20
    }
};
export const scrollablePaneStyles: Partial<IScrollablePaneStyles> = {
    root: {
        marginTop: '98px'
    }
};
export const viewSubHeaderAlignmentStyles: IStackStyles = {
    root: {
        background: DefaultPalette.themeLighter,
        width: 400,
        height: 25,
        marginLeft: -45
    },
};
export const importPanelSubheaderAlignmentStyles: IStackStyles = {
    root: {
        background: DefaultPalette.themeLighter,
        width: 510,
        height: 25,
        marginLeft: '-45px',
        marginTop: '15px'
    }
};
export const importPanelGroupHeaderStyles: Partial<IGroupHeaderStyles> = {
    root: {
        height: 55,
        backgroundColor: '#F9F9F9',
        border: '1px solid #e1e8e8'
    }
};
export const importPanelGroupDetailRowStyles: Partial<IGroupHeaderStyles> = {
    root: {
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: '#e1e8e8'
    }
};
export const viewSubHeaderContentAlignmentStyles: React.CSSProperties = {
    display: 'flex',
    paddingLeft: 30,
    paddingTop: 2
};

// Styles definition
export const itemStylesT: React.CSSProperties = {
    alignItems: 'center',
    display: 'flex',
    height: 50,
    paddingLeft: 10,
    justifyContent: 'center',
    width: 50
};

export const stackItemDocumentTitleStyles: IStackItemStyles = {
    root: {
        color: DefaultPalette.black,
        fontSize: 20,
        fontWeight: 100
    }
};
export const stackItemDocumentSubTitleStyles: IStackItemStyles = {
    root: {
        color: DefaultPalette.black,
        fontSize: 11,
        fontStyle: 'italic'
    }
};
export const importPanelHeaderStyle: IStackStyles = {
    root: {
        height: 68,
        width: 483,
        border: '0px solid #000'
    },
};
export const itemAlignmentsStackStyles: IStackStyles = {
    root: {
        height: 'auto',
        width: 400,
    },
};
export const itemAlignmentsStackStyles1: IStackStyles = {
    root: {
        height: 'auto',
        width: 68,
    },
};
export const itemAlignmentsStackStyles2: IStackStyles = {
    root: {
        height: 'auto',
        width: 270
    },
};

export const itemAlignmentsStackTokens: IStackTokens = {
    padding: 5
};
export const disabledComboboxButtonBackground: React.CSSProperties = {
    background: DefaultPalette.white
};
export const customStyles: Partial<IComboBoxStyles> = {
    root: {
        width: '360px',
        maxWidth: '430px'
    },
    optionsContainerWrapper: {
        maxWidth: '430px'
    }
};
export const archiveMessageBarStyle: IMessageBarStyles = {
    text: {
        marginTop: '-4px',
        fontSize: '14px'
    }
};

export const comboboxDropdownButton: Partial<IButtonStyles> = {
    root: {
        display: 'none'
    }
};

export const progressIndicatorStyles: Partial<IProgressIndicatorStyles> = {
    root: {
        paddingLeft: '5px',
        paddingRight: '5px',
        textAlign: 'right'
    }
};

// modal styles
const theme = getTheme();
export const modalContentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        theme.fonts.xLarge,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
export const modalIconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};