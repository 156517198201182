import { CommandBar, DefaultButton, ICommandBarItemProps, PrimaryButton, Toggle } from "@fluentui/react";
import { FC } from "react";
import i18n from '../../i18n';
import { useP360 } from "../../context/P360Context";
import HelpIcon from '../../images/help.svg';
import { IMessageItem } from "../../model/model";
import { formatVersionForHelpUrl } from "../../helpers/Mapper";
import { useMessages } from "../../context/MessagesContext";
import { t } from "i18next";

interface IChatCommandBarProps {
    selectedMessages?: IMessageItem[];
    archiveHandler: () => void;
    filterHandler: () => void;
    profilePhotoHandler: (checked?: boolean) => void;
    attachmentHandler: (checked?: boolean) => void;
    clearSelection: () => void;
}

const ChatCommandBar: FC<IChatCommandBarProps> = (props) => {
    const { p360State } = useP360();
    const { messageState } = useMessages();
    const { selectedMessages } = props;
    const hasSelectedFiles = selectedMessages && selectedMessages.length > 0;

    const onRenderArchiveAllButton = () => {
        return <div style={{ paddingTop: '10px' }}>
            <PrimaryButton text={i18n.t('Archive all to 360°')} iconProps={{ iconName: 'refresh' }} onClick={() => props.archiveHandler()}
                disabled={messageState.initialMessages.length === 0 || messageState.messages.length === 0 || (selectedMessages && selectedMessages.length > 0)} />
        </div>;
    };

    const onRenderArchiveSelectedButton = () => {
        return <div style={{ paddingTop: '10px', marginLeft: '5px' }}>
            <DefaultButton text={i18n.t('Archive selected to 360°')} iconProps={{ iconName: 'refresh' }} onClick={() => props.archiveHandler()}
                disabled={messageState.initialMessages.length === 0 || messageState.messages.length === 0 || selectedMessages?.length === 0} />
        </div>;
    };

    const commandItems: ICommandBarItemProps[] = [
        {
            key: 'archiveAll',
            onRender: onRenderArchiveAllButton
        },
        {
            key: 'archiveSelected',
            onRender: onRenderArchiveSelectedButton
        }
    ];

    const onRenderAttachmentButton = () => {
        return <div style={{ paddingTop: '10px', marginLeft: '5px', marginRight: '5px' }}>
            <Toggle disabled={messageState.initialMessages.length === 0 && messageState.messages.length === 0} label={t("Include attachments")} inlineLabel
                defaultChecked={true} styles={{ label: { marginLeft: '5px' } }} onChange={(event: React.MouseEvent<HTMLElement>, checked?: boolean) => props.attachmentHandler(checked)} />
        </div>;
    };
    const onRenderPhotosButton = () => {
        return <div style={{ paddingTop: '10px', marginLeft: '5px', marginRight: '5px' }}>
            <Toggle disabled={messageState.initialMessages.length === 0 && messageState.messages.length === 0} label={t("Include avatars")} inlineLabel
                styles={{ label: { marginLeft: '5px' } }} onChange={(event: React.MouseEvent<HTMLElement>, checked?: boolean) => props.profilePhotoHandler(checked)} />
        </div>;
    };
    let farCommandItems: ICommandBarItemProps[] = [
        {
            key: 'filterMenu',
            title: i18n.t('Filter'),
            text: i18n.t('Filter'),
            disabled: messageState.initialMessages.length === 0 && messageState.messages.length === 0,
            iconProps: { iconName: 'QueryList' },
            onClick: () => {
                props.filterHandler();
            }
        },
        {
            key: 'attachmentMenu',
            onRender: onRenderAttachmentButton
        },
        {
            key: 'photoMenu',
            onRender: onRenderPhotosButton
        },
        {
            key: 'helpMenu',
            title: i18n.t('Help'),
            iconProps:
            {
                imageProps: {
                    src: HelpIcon,
                    width: 16,
                    height: 16
                }
            },
            onClick: () => {
                window.open(i18n.t('HelpPageUrl', { p360version: formatVersionForHelpUrl(p360State.backendCapabilities?.Information?.Version) }), "_blank");
            }
        }
    ];

    if (hasSelectedFiles) {
        const chatSelectionKey = 'chatSelection';
        const chatSelectionCommandItemIndex = farCommandItems.findIndex(f => f.key === chatSelectionKey);
        if (chatSelectionCommandItemIndex !== -1) {
            farCommandItems[chatSelectionCommandItemIndex].text = `${selectedMessages.length} ${i18n.t('Selected')}`;
        }
        else {
            farCommandItems.push(
                {
                    key: chatSelectionKey,
                    text: `${selectedMessages.length} ${i18n.t('Selected')}`,
                    iconProps: { iconName: 'cancel' },
                    title: i18n.t('Clear selection'),
                    onClick: () => {
                        props.clearSelection();
                    }
                }
            );
        }
        const temp = [...farCommandItems];
        farCommandItems = [temp.pop() as ICommandBarItemProps, ...temp];
    }
    return <CommandBar
        items={commandItems}
        farItems={farCommandItems}
        ariaLabel={i18n.t("Use left and right arrow keys to navigate between commands")}
    />;
};

export default ChatCommandBar;